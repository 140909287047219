import geofences from './geofences'
import users from './users'
import rewards from './rewards'

const queries = {
  geofences,
  users,
  rewards,
}

export default queries
