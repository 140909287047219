import React from 'react'
import styled from 'styled-components/macro'
import { Box } from 'rebass'

import Link from './Link'
import Hide from './Hide'

import logo from '../assets/img/logo.svg'
import logoText from '../assets/img/logo-text.svg'

const Logo = styled(Box)`
  height: ${({ height }) => height || 37}px;
`

const Navigation = ({ responsive, ...others }) => (
  <Link to="/">
    {responsive
      ? (
        <>
          <Hide sm md lg xl><Logo as="img" {...others} src={logo} alt="dot dot data logo" /></Hide>
          <Hide xs><Logo as="img" {...others} src={logoText} alt="dot dot data logo" /></Hide>
        </>
      )
      : <Logo as="img" {...others} src={logoText} alt="dot dot data logo" />
    }
  </Link>
)

export default Navigation
