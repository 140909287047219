import React from 'react'
import { useQuery } from 'react-apollo-hooks'
import { Flex, Text, Heading } from 'rebass'
import { css } from 'styled-components/macro'

import Button from '../Button'
import Card from '../Card'
import Link from '../Link'

import queries from '../../queries'
import routes from '../../constants/routes'
import Loading from '../Loading'

const UsersList = () => {
  const { data, error, loading } = useQuery(queries.users.ALL_FIELDS, {
    suspend: false,
    fetchPolicy: 'network-only',
  })
  if (loading) return <Loading />
  return (
    <>
      {error && error.message}
      {!error && (
        <Card p={0}>
          <Flex
            p={3}
            to={routes.USERS_NEW}
            as={Link}
          >
            <Button fill>Create a New User</Button>
          </Flex>
          <Flex
            as="ul"
            p={0}
            m={0}
            flexDirection="column"
          >
            {data.users.map(u => (
              <Flex
                key={u.id}
                as="li"
                width="100%"
                css={css`
                  background-color: white;

                  &:hover {
                    background-color: ${({ theme }) => theme.colors.header};
                  }
                `}
              >
                <Link
                  width="100%"
                  p={3}
                  to={`${routes.USERS_ROOT}/${u.id}`}
                >
                  <Flex justifyContent="space-between">
                    <Flex flexDirection="column" justifyContent="center">
                      <Heading as="h3">{`${u.firstName} ${u.lastName}`}</Heading>
                      <Text color="textLight" mt={2}>{u.email}</Text>
                    </Flex>
                    <Text
                      bg={u.roles.includes('ADMIN') ? 'primary' : 'secondary'}
                      color="white"
                      fontSize={1}
                      alignSelf="flex-start"
                      p={1}
                      css={css`border-radius: 3px`}
                    >
                      {u.roles}
                    </Text>
                  </Flex>
                </Link>
              </Flex>
            ))}
          </Flex>
        </Card>
      )}
    </>
  )
}

export default UsersList
