import React, { useState } from 'react'
import { Text } from 'rebass'
import { Formik, Form } from 'formik'

import { TextInput } from '../Form'
import Button from '../Button'

import { signInSchema } from '../../utilities/validations'
import { auth } from '../../utilities/firebase'

const SignInForm = () => {
  const [error, setError] = useState(null)

  const onSubmit = async ({ email, password }, { setSubmitting }) => {
    setSubmitting(true)
    setError(null)

    try {
      await auth.signInWithEmailAndPassword(email, password)
    } catch (e) {
      setError('We cannot validate that username or password')
    }

    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={signInSchema}
      onSubmit={onSubmit}
      render={({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <TextInput name="email" type="text" placeholder="Email address" autoComplete="email-address" />
          <TextInput name="password" type="password" placeholder="Password" autoComplete="password" />
          {error && <Text color="error">{error}</Text>}
          <Button type="submit" fill disabled={isSubmitting}>Log in</Button>
        </Form>
      )}
    />
  )
}

export default SignInForm
