import { gql } from 'apollo-boost'

const ME = gql`
  {
    me {
      id
      firstName
      email
      roles
    }
  }
`

const ALL_FIELDS = gql`
{
  users {
    id
    email
    authId
    firstName
    lastName
    roles
  }
}
`

const GENERAL = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      authId
      firstName
      lastName
      roles
    }
  }
`

const SELECT = gql`
{
  users {
    id
    email
    firstName
    lastName
    businessAccounts {
      id
    }
  }
}
`

const PROFILE = gql`
{
  me {
    id
    email
    firstName
    lastName
  }
}
`

const BUSINESS = gql`
{
  me {
    id
    businessAccounts {
      id
      name
      description
      geofenceLimit
      rewardLimit
    }
  }
}
`

const ACCOUNT_SELECT = gql`
{
  accounts {
    id
    name
    type
  }
}
`

const USER_BUSINESS = gql`
query User($id: ID!) {
  user(id: $id) {
    id
    businessAccounts {
      id
      name
      description
      geofenceLimit
      rewardLimit
    }
  }
}
`

const LIMITS = gql`
{
  me {
    id
    businessAccounts {
      id
      geofenceLimit
      rewardLimit
    }
  }
}
`

const users = {
  ME,
  ALL_FIELDS,
  GENERAL,
  SELECT,
  PROFILE,
  BUSINESS,
  ACCOUNT_SELECT,
  USER_BUSINESS,
  LIMITS,
}

export default users
