import { useLayoutEffect } from 'react'
import useLoadingValue from './useLoadingValue'

import { auth } from './firebase'

const useAuthState = () => {
  const { loading, setValue, value } = useLoadingValue(auth.currentUser)

  const getUser = async (user) => {
    const idTokenResult = user
      ? await auth.currentUser.getIdTokenResult()
      : {}
    setValue({
      ...user,
      ...idTokenResult.claims,
    })
  }

  useLayoutEffect(() => {
    const listener = auth.onAuthStateChanged(getUser)
    return () => listener()
  }, [auth])

  return {
    loading,
    user: value,
  }
}

export default useAuthState
