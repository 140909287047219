import React from 'react'
import ReactSelect from 'react-select'
import { Flex, Text } from 'rebass'
import { useField, useFormikContext } from 'formik'
import { useQuery } from 'react-apollo-hooks'
import get from 'lodash.get'
import { css } from 'styled-components/macro'

import queries from '../../queries'

const inputStyle = {
  control: (styles, { isFocused }) => ({
    display: 'flex',
    padding: '.3rem',
    backgroundColor: '#eff2f7',
    border: isFocused ? '1px solid #E96264' : '1px solid transparent',
    hover: {
      border: '1px solid #E96264',
    },
    '$:hover': {
      borderColor: '#E96264',
    },
    boxShadow: 'none',
    borderRadius: '3px',
  }),
  input: () => ({
    backgroundColor: '#eff2f7',
  }),
  placeholder: () => ({
    color: '#AFB8C6',
    fontWeight: 300,
  }),
}

const AccountSelect = ({
  name,
  label,
  ...others
}) => {
  const { data, error, loading } = useQuery(queries.users.ACCOUNT_SELECT, {
    suspend: false,
    fetchPolicy: 'network-only',
  })
  const [field, meta] = useField(name)
  const { setFieldValue, setFieldTouched } = useFormikContext()

  const options = get(data, 'accounts', []).map(a => ({
    value: a.id,
    label: `${a.name} (${a.type}) - ${a.id}`,
  }))

  // Manually update Formik values object
  const handleChange = value => setFieldValue(name, value ? value.value : '')

  // Manually update Formik touched object
  const handleBlur = () => setFieldTouched(name, true)

  return (
    <Flex
      flexDirection="column"
      mb={3}
      width="100%"
    >
      {label && (
        <Text
          as="label"
          mb={2}
          fontSize={1}
          fontWeight="bold"
          color="3B4858"
          css={css`text-transform: uppercase;`}
        >
          {label}
        </Text>
      )}
      <Text
        {...others}
        mb={1}
        fontSize={2}
        as={ReactSelect}
        name={name}
        value={options.find(o => field.value === o.value)}
        onChange={handleChange}
        onBlur={handleBlur}
        isLoading={loading}
        isClearable
        isSearchable
        options={options}
        styles={inputStyle}
      />
      {meta.touch && meta.error && (
        <Text color="error">
          {meta.error}
        </Text>
      )}
      {error && (
        <Text color="error">
          {error.message}
        </Text>
      )}
    </Flex>
  )
}

export default AccountSelect
