import React from 'react'

import Section from '../Section'
import Profile from './Profile'
import Account from './Account'
import Limits from './Limits'

const Settings = () => (
  <>
    <Section
      heading="Profile"
      text="Your personal account information and preferences"
    >
      <Profile />
    </Section>
    <Section
      heading="Account"
      text="Information about your account"
    >
      <Account />
      <Limits />
    </Section>
  </>
)

export default Settings
