import React, { useState } from 'react'
import { Paragraph } from 'rebass'
import { Formik } from 'formik'

import { TextInput } from '../Form'
import Button from '../Button'

import { passwordResetSchema } from '../../utilities/validations'
import useAuthState from '../../utilities/useAuthState'

const PasswordResetForm = () => {
  const [messages, setMessages] = useState({})
  const { sendPasswordResetEmail } = useAuthState()

  const onSubmit = async ({ email }, { setSubmitting }) => {
    setSubmitting(true)
    setMessages({})

    try {
      await sendPasswordResetEmail(email)
      setMessages({ success: 'We just sent you an email to reset your password' })
    } catch (error) {
      setMessages({ error })
    }

    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={passwordResetSchema}
      onSubmit={onSubmit}
      render={({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <TextInput name="email" type="text" placeholder="Email address" />
          {messages.error && <Paragraph color="status-critical">{messages.error}</Paragraph>}
          {messages.success && <Paragraph color="status-ok">{messages.success}</Paragraph>}
          <Button fill type="submit" disabled={isSubmitting}>Submit</Button>
        </form>
      )}
    />
  )
}

export default PasswordResetForm
