import React from 'react'
import { Link as RouterLink } from '@reach/router'
import { Link } from 'rebass'
import { css } from 'styled-components/macro'

export default ({
  href,
  blank,
  active,
  ...others
}) => (
  <Link
    as={href ? Link : RouterLink}
    target={href && blank && '_blank'}
    href={href}
    css={css`
      text-decoration: none;
      color: ${({ theme }) => (active ? theme.colors.primary : theme.colors.text.normal)};
    `}
    {...others}
  />
)
