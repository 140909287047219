import React from 'react'

import AppBar from './AppBar'
import Links from './Links'

const Home = ({ links, children }) => (
  <header>
    <AppBar />
    <Links links={links} />
    {children}
  </header>
)

export default Home
