import React, { useState } from 'react'
import { useMutation } from 'react-apollo-hooks'
import { navigate } from '@reach/router'
import { Text } from 'rebass'
import Modal from 'styled-react-modal'

import Card from '../Card'
import Section from '../Section'
import Button from '../Button'

import mutations from '../../mutations'
import routes from '../../constants/routes'

const DangerZone = ({ id }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [modal, setModal] = useState(false)
  const deleteGeofence = useMutation(mutations.geofences.DELETE)

  const onSubmit = async () => {
    setSubmitting(true)
    setSuccess(null)
    setError(null)

    try {
      await deleteGeofence({ variables: { id } })
      setSuccess('Zone was successfully deleted')
      setTimeout(() => {
        // replace route so user cannot navigate back
        navigate(routes.GEOFENCES_ROOT, { replace: true })
      }, 1000)
    } catch (e) {
      setError(e.message)
      setSubmitting(false)
    }
  }

  const toggleModal = () => {
    if (submitting) return
    setSuccess(null)
    setError(null)
    setModal(!modal)
  }

  return (
    <Section
      heading="Danger Zone"
      text="Irreversible and destructive actions. Tread lightly."
    >
      <Card
        heading="Delete Zone"
        text="Once you delete a zone, there is no going back."
      >
        <Modal
          isOpen={modal}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
        >
          <Card
            heading="Confirm Deletion"
            text="If you are not sure then you should deactivate this zone instead. Are you sure you want to delete?"
          >
            <Button outline mr={3} disabled={submitting} onClick={toggleModal}>Keep this Zone</Button>
            <Button color="error" disabled={submitting} mt={[3, 0]} onClick={onSubmit}>Delete this Zone</Button>
            {error && <Text color="error" mt={3}>{error}</Text>}
            {success && <Text color="success" mt={3}>{success}</Text>}
          </Card>
        </Modal>
        <Button color="error" onClick={toggleModal}>Delete this Zone</Button>
      </Card>
    </Section>
  )
}

export default DangerZone
