import React from 'react'
import { Box, Text, Heading } from 'rebass'
import { Formik, Form } from 'formik'
import { css } from 'styled-components/macro'

import Button from '../Button'

import { shake } from '../../constants/keyFrames'

export default ({
  initialValues,
  validationSchema,
  onSubmit,
  onValidate,
  heading,
  text,
  success,
  error,
  submitLabel,
  children,
  ...others
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    validate={onValidate}
    render={({ isSubmitting, errors, submitCount }) => {
      // Consider true if we have errors and form was submitted at least onced, we
      // need to use submitCount else we end up with Button disabled before all
      // errors are visible on screen
      const badInput = !!Object.keys(errors).length && submitCount > 0
      return (
        <Box
          as={Form}
          width="100%"
          {...others}
        >
          {heading && <Heading as="h2">{heading}</Heading>}
          {text && <Text color="textLight" mt={2}>{text}</Text>}
          {children}
          <Button
            css={badInput ? css`${shake}` : null}
            type="submit"
            fill
            mt={1}
            disabled={isSubmitting || badInput}
          >
            {isSubmitting ? 'Saving...' : (submitLabel || 'Submit')}
          </Button>
          {success && <Text color="success" mt={4} textAlign="center">{success}</Text>}
          {error && <Text color="error" mt={4} textAlign="center">{error}</Text>}
          {badInput && <Text textAlign="center" color="error" mt={4}>Please check your input, some required fields are empty or have errors.</Text>}
        </Box>
      )
    }}
  />
)
