import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import {
  TextInput,
  Toggle,
  FormSection,
  Form,
} from '../Form'
import Card from '../Card'
import Section from '../Section'
import Loading from '../Loading'
import NotFound from '../NotFound'
import DangerZone from './DangerZone'

import { updateRewardSchema } from '../../utilities/validations'
import queries from '../../queries'
import mutations from '../../mutations'

const Edit = ({ id }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { data, error: queryError, loading } = useQuery(queries.rewards.EDIT, {
    variables: { id },
    suspend: false,
  })
  const updateReward = useMutation(mutations.rewards.UPDATE)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setSuccess(null)
    setError(null)

    const variables = {
      ...updateRewardSchema.cast(values),
      id,
      status: values.status ? 'ACTIVE' : 'INACTIVE',
    }

    try {
      await updateReward({ variables })
      setSuccess('Reward was successfully updated')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  if (queryError && queryError.message) return <Text color="error">{queryError.message}</Text>
  if (!loading && !data.reward) return <NotFound noMargin />

  return (
    <>
      <Section
        heading="Update Reward"
        text="Give your customers a reason to keep coming back."
      >
        <Card>
          {loading
            ? <Loading />
            : (
              <Form
                initialValues={{
                  name: get(data, 'reward.name', ''),
                  description: get(data, 'reward.description', ''),
                  points: get(data, 'reward.points', ''),
                  status: get(data, 'reward.status', '') === 'ACTIVE',
                }}
                validationSchema={updateRewardSchema}
                onSubmit={onSubmit}
                submitLabel="Update Reward"
                error={error}
                success={success}
              >
                <FormSection>
                  <Toggle name="status" label={['Active', 'Inactive']} />
                </FormSection>

                <FormSection
                  heading="Public"
                  text="This information will appear in the mobile application."
                >
                  <TextInput name="name" placeholder="Catchy and descriptive name" label="Reward Name" />
                  <TextInput name="description" placeholder="Describe this reward in detail" label="Reward Description" area />
                </FormSection>

                <FormSection
                  heading="Points"
                  text="Points are the currency used to redeem this reward. When
                determining the cost of this reward keep in mind how many points your customers receive by crossing
                into your zones."
                >
                  <TextInput name="points" placeholder="Points required to redeem this reward" type="number" label="Point Value" />
                </FormSection>
              </Form>
            )
        }
        </Card>
      </Section>
      <DangerZone id={id} />
    </>
  )
}

export default Edit
