import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import {
  TextInput,
  Toggle,
  InputGroup,
  FormSection,
  Form,
} from '../Form'
import Card from '../Card'
import Section from '../Section'
import Loading from '../Loading'
import NotFound from '../NotFound'
import DangerZone from './DangerZone'

import { updateGeofenceSchema } from '../../utilities/validations'
import queries from '../../queries'
import mutations from '../../mutations'

const Edit = ({ id }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { data, error: queryError, loading } = useQuery(queries.geofences.EDIT, {
    variables: { id },
    suspend: false,
  })
  const updateGeofence = useMutation(mutations.geofences.UPDATE)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setSuccess(null)
    setError(null)

    const variables = {
      ...updateGeofenceSchema.cast(values),
      id,
      status: values.status ? 'ACTIVE' : 'INACTIVE',
    }

    try {
      await updateGeofence({ variables })
      setSuccess('Zone was successfully updated')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  if (queryError && queryError.message) return <Text color="error">{queryError.message}</Text>
  if (!loading && !data.geofence) return <NotFound noMargin />

  return (
    <>
      <Section
        heading="Update Zone"
        text="Give points to your loyal customers."
      >
        <Card>
          {loading
            ? <Loading />
            : (
              <Form
                initialValues={{
                  name: get(data, 'geofence.name', ''),
                  radius: get(data, 'geofence.radius', ''),
                  latitude: get(data, 'geofence.latitude', ''),
                  longitude: get(data, 'geofence.longitude', ''),
                  status: get(data, 'geofence.status', '') === 'ACTIVE',
                  points: get(data, 'geofence.points', ''),
                  refreshRate: get(data, 'geofence.refreshRate', ''),
                }}
                validationSchema={updateGeofenceSchema}
                onSubmit={onSubmit}
                submitLabel="Update Zone"
                error={error}
                success={success}
              >
                <FormSection>
                  <TextInput name="name" placeholder="Enter a name to help you identify this zone (only you will see this)" label="Zone Name" />
                  <Toggle name="status" label={['Active', 'Inactive']} />
                </FormSection>

                <FormSection
                  heading="Location"
                  text="Place your zone where your customers are. Typically, that will
                be your primary place of business. A wider zone can entice people visiting
                neighboring businesses."
                >
                  <TextInput name="radius" placeholder="5 (meters)" type="number" label="Radius in meters" />
                  <InputGroup>
                    <TextInput name="latitude" placeholder="26.209698" type="number" label="Latitude" />
                    <TextInput name="longitude" placeholder="-98.233922" type="number" label="Longitude" />
                  </InputGroup>
                </FormSection>

                <FormSection
                  heading="Points"
                  text="Points are awarded to your customers whenever they cross into your zone. To
                keep customers from being overly awarded you must specify a refresh rate in
                minutes."
                >
                  <InputGroup>
                    <TextInput name="points" placeholder="5 (on zone entry)" type="number" label="Points Awarded on Entry" />
                    <TextInput name="refreshRate" placeholder="300 (minutes)" type="number" label="Refresh Rate" />
                  </InputGroup>
                </FormSection>
              </Form>
            )
        }
        </Card>
      </Section>
      <DangerZone id={id} />
    </>
  )
}

export default Edit
