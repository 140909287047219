import React, { useState, useEffect } from 'react'
import { useQuery, useApolloClient } from 'react-apollo-hooks'
import { Flex, Button, Card } from 'rebass'
import { css } from 'styled-components/macro'

import Link from '../Link'

import queries from '../../queries'
import routes from '../../constants/routes'
import { auth } from '../../utilities/firebase'

const Dropdown = () => {
  const { data } = useQuery(queries.users.ME, { suspend: false })
  const client = useApolloClient()
  const [showMenu, setShowMenu] = useState(false)

  const show = () => !showMenu && setShowMenu(true)
  const hide = () => showMenu && setShowMenu(false)

  const signOut = async () => {
    try {
      await auth.signOut()
      // Apollo caches all query results, get rid of them when the login state changes
      client.clearStore()
    } catch (e) {
      // do nothing
    }
  }

  useEffect(() => {
    document.addEventListener('click', hide)
    return () => document.removeEventListener('click', hide)
  })

  return (
    <Flex
      p={1}
      css={css`position: relative;`}
    >
      <svg
        onClick={show}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        css={css`cursor: pointer;`}
      >
        <path
          d="M12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12
             20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4ZM12
             2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52
             22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 15L8 11H16L12 15Z"
          fill="#8592A6"
        />
      </svg>
      {showMenu && (
      <Card
        as="ul"
        borderRadius={3}
        border="1px solid #EEEEEE"
        boxShadow="0 2px 4px 0 rgba(14,30,37,.12)"
        flexDirection="column"
        m={0}
        p={0}
        css={css`            
          list-style-type: none;
          top: 35px;
          right: 0px;
          background-color: white;
          position: absolute;
          z-index: 1;

          a {
            display: block;
            padding: 8px 16px;
            color: ${({ theme }) => theme.colors.text.normal};
            text-decoration: none;   
            cursor: pointer; 
          }

          button {
            padding: 8px 16px;
            color: ${({ theme }) => theme.colors.text.normal};
            width: 100%;
            height: 100%;
            text-align: left;
            background-color: white;  
            cursor: pointer; 
            border: none;
            font-size: 1rem;
            font-weight: normal;
            outline: none;
          }

          a:hover, button:hover {
            background-color: ${({ theme }) => theme.colors.header};
          }
        `}
      >
        <li><Link to={routes.ACCOUNT_SETTINGS}>{data && data.me && data.me.email}</Link></li>
        <li><Link to={routes.ACCOUNT_SETTINGS}>Account Settings</Link></li>
        <li><Button onClick={signOut}>Sign Out</Button></li>
      </Card>
      )}
    </Flex>
  )
}

export default Dropdown
