import React from 'react'

import Section from '../Section'
import General from './General'
import Account from './Account'

const Edit = ({ id }) => (
  <>
    <Section
      heading="General"
      text="Basic user details"
    >
      <General id={id} />
    </Section>
    <Section
      heading="Account"
      text="Account details"
    >
      <Account id={id} />
    </Section>
  </>
)

export default Edit
