import React from 'react'
import { css } from 'styled-components/macro'
import { Heading, Text, Flex } from 'rebass'

import Link from '../Link'
import Container from '../Container'
import Logo from '../Logo'
import SignInForm from './SignInForm'
import PasswordResetLink from './PasswordResetLink'

const SignIn = () => (
  <Flex
    flexDirection="column"
    alignItems="center"
    as={Container}
    css={css`max-width: 500px;`}
  >
    <Logo
      m={4}
      height={75}
    />
    <Heading mb={4}>Log in to your account</Heading>
    <Text mb={4}>
      {'Don\'t have an account yet? '}
      <Link
        target="_blank"
        color="primary"
        href="https://www.dotdotdata.com/contact/"
      >
        Request access
      </Link>
    </Text>
    <SignInForm />
    <PasswordResetLink />
  </Flex>
)

export default SignIn
