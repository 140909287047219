import { gql } from 'apollo-boost'

const ALL_FIELDS = gql`
  {
    rewards {
      id
      name
      description
      points
      status
      account {
        id
        name
      }
    }
  }
`

const LIST = gql`
{
  rewards {
    id
    name
    status
    account {
      id
      name
    }
  }
}
`

const EDIT = gql`
  query Reward($id: ID!) {
    reward(id: $id) {
      id
      name
      description
      points
      status
    }
  }
`

const rewards = {
  ALL_FIELDS,
  LIST,
  EDIT,
}

export default rewards
