import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import { Form, TextInput } from '../Form'
import Card from '../Card'
import Loading from '../Loading'

import { updateAccountSchemaNoLimit } from '../../utilities/validations'
import queries from '../../queries'
import mutations from '../../mutations'

const Account = () => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { data, error: queryError, loading } = useQuery(queries.users.BUSINESS, {
    suspend: false,
    fetchPolicy: 'network-only',
  })
  const updateAccount = useMutation(mutations.users.ACCOUNT, {
    // needed because `queries.users.BUSINESS` and `mutations.users.BUSINESS` hit different collections
    refetchQueries: queries.users.BUSINESS,
  })

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setError(null)
    setSuccess(null)

    const variables = {
      ...updateAccountSchemaNoLimit.cast(values),
      id: get(data, 'me.businessAccounts[0].id', ''),
    }

    try {
      await updateAccount({ variables })
      setSuccess('Your account was updated')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  if (queryError && queryError.message) return <Text color="error">{queryError.message}</Text>

  return (
    <Card
      heading="Business"
      text="This information is publicly displayed in our mobile application."
    >
      {loading
        ? <Loading />
        : (
          <Form
            initialValues={{
              name: get(data, 'me.businessAccounts[0].name', ''),
              description: get(data, 'me.businessAccounts[0].description', '') || '', // in case null
            }}
            validationSchema={updateAccountSchemaNoLimit}
            onSubmit={onSubmit}
            success={success}
            error={error}
          >
            <TextInput name="name" placeholder="Business Name" label="Name" />
            <TextInput name="description" placeholder="Business Description" label="Description" area />
          </Form>
        )
      }
    </Card>
  )
}

export default Account
