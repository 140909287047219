import { gql } from 'apollo-boost'

const CREATE = gql`
  mutation CreateGeofence(
    $name: String!
    $radius: Float!
    $latitude: Float!
    $longitude: Float!
    $status: GeofenceStatus!
    $points: Int!
    $refreshRate: Int!
    $account: ID
  ) {
    createGeofence(
      data: {
        name: $name
        radius: $radius
        latitude: $latitude
        longitude: $longitude
        status: $status
        points: $points
        refreshRate: $refreshRate
        account: $account
      }
    ) {
      id
      radius
      name
      latitude
      longitude
      status
      points
      refreshRate
      account {
        id
      }
    }
  }
`

const UPDATE = gql`
  mutation UpdateGeofence(
    $id: ID!
    $name: String!
    $radius: Float!
    $latitude: Float!
    $longitude: Float!
    $status: GeofenceStatus!
    $points: Int!
    $refreshRate: Int!
  ) {
    updateGeofence(
      id: $id
      data: {
        name: $name
        radius: $radius
        latitude: $latitude
        longitude: $longitude
        status: $status
        points: $points
        refreshRate: $refreshRate
      }
    ) {
      id
      radius
      name
      latitude
      longitude
      status
      points
      refreshRate
    }
  }
`

const DELETE = gql`
  mutation DeleteGeofence($id: ID!) {
    deleteGeofence(id: $id) {
      id
    }
  }
`

const geofences = {
  CREATE,
  UPDATE,
  DELETE,
}

export default geofences
