import { gql } from 'apollo-boost'

const CREATE = gql`
  mutation CreateUser(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $roles: [UserRole!]!
  ) {
    createUser(
      data: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        roles: $roles
      }
    ) {
      id
      authId
      email
      firstName
      lastName
      roles
    }
  }
`

const UPDATE = gql`
  mutation UpdateUser(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $roles: [UserRole!]!
  ) {
    updateUser(
      id: $id
      data: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        roles: $roles
      }
    ) {
      id
      email
      firstName
      lastName
      roles
    }
  }
`

const PROFILE = gql`
  mutation UpdateUser(
    $id: ID!
    $email: String
    $password: String
    $firstName: String
    $lastName: String
  ) {
    updateUser(
      id: $id
      data: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      id
      email
      firstName
      lastName
    }
  }
`

const ACCOUNT = gql`
  mutation UpdateAccount(
    $id: ID!
    $name: String
    $description: String
    $geofenceLimit: Int
    $rewardLimit: Int
  ) {
    updateAccount(
      id: $id
      data: {
        name: $name
        description: $description
        geofenceLimit: $geofenceLimit
        rewardLimit: $rewardLimit
      }
    ) {
      id
      name
      description
      geofenceLimit
      rewardLimit
    }
  }
`

const users = {
  CREATE,
  UPDATE,
  PROFILE,
  ACCOUNT,
}

export default users
