import { gql } from 'apollo-boost'

const ALL_FIELDS = gql`
  {
    geofences {
      id
      radius
      name
      latitude
      longitude
      status
      points
      refreshRate    
    }
  }
`

const LIST = gql`
  {
    geofences {
      id
      name
      status
      account {
        id
        name
      }  
    }
  }
`

const EDIT = gql`
  query Geofence($id: ID!) {
    geofence(id: $id) {
      id
      radius
      name
      latitude
      longitude
      status
      points
      refreshRate   
    }
  }
`

const geofences = {
  ALL_FIELDS,
  LIST,
  EDIT,
}

export default geofences
