import React from 'react'
import { useQuery } from 'react-apollo-hooks'
import { Flex, Text, Heading } from 'rebass'
import { css } from 'styled-components/macro'

import Button from '../Button'
import Card from '../Card'
import Link from '../Link'
import Status from '../Status'
import Loading from '../Loading'

import queries from '../../queries'
import routes from '../../constants/routes'

const GeofencesList = () => {
  const { data, error, loading } = useQuery(queries.geofences.LIST, {
    suspend: false,
    fetchPolicy: 'network-only',
  })
  if (loading) return <Loading />
  return (
    <>
      {error && error.message}
      {!error && (
        <Card p={0}>
          <Flex
            p={3}
            to={routes.GEOFENCES_NEW}
            as={Link}
          >
            <Button fill>Create a New Zone</Button>
          </Flex>
          <Flex
            as="ul"
            p={0}
            m={0}
            flexDirection="column"
          >
            {data.geofences.map(g => (
              <Flex
                key={g.id}
                as="li"
                width="100%"
                css={css`
                  background-color: white;

                  &:hover {
                    background-color: ${({ theme }) => theme.colors.header};
                  }
                `}
              >
                <Link
                  width="100%"
                  p={3}
                  to={`${routes.GEOFENCES_ROOT}/${g.id}`}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex flexDirection="column">
                      <Heading as="h3">{g.name}</Heading>
                      <Text color="textLight" mt={2}>{`Owned by ${g.account.name}`}</Text>
                    </Flex>
                    <Flex flexDirection="column">
                      <Status active={g.status === 'ACTIVE'} />
                      <Text mt={2}>{g.status === 'ACTIVE' ? 'Activated' : 'Not active'}</Text>
                    </Flex>
                  </Flex>
                </Link>
              </Flex>
            ))}
          </Flex>
        </Card>
      )}
    </>
  )
}

export default GeofencesList
