import geofences from './geofences'
import rewards from './rewards'
import users from './users'

const queries = {
  geofences,
  rewards,
  users,
}

export default queries
