import React from 'react'
import { Flex } from 'rebass'
import { css } from 'styled-components/macro'

import Dropdown from './Dropdown'
import Container from '../Container'
import Logo from '../Logo'

const AuthenticatedNav = ({ links }) => (
  <Flex
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    width="100%"
    py={2}
    css={css`background-color: ${props => props.theme.colors.header};`}
  >
    <Flex
      as={Container}
      justifyContent="space-between"
    >
      <Logo responsive />
      <Flex alignItems="center">
        {links}
        <Dropdown />
      </Flex>
    </Flex>
  </Flex>
)

export default AuthenticatedNav
