import React, { useState } from 'react'
import { useMutation } from 'react-apollo-hooks'
import { navigate } from '@reach/router'

import {
  TextInput,
  Toggle,
  InputGroup,
  FormSection,
  Form,
  AccountSelect,
} from '../Form'
import Card from '../Card'
import Section from '../Section'

import { createGeofenceSchema } from '../../utilities/validations'
import mutations from '../../mutations'
import routes from '../../constants/routes'

const initialValues = {
  name: '',
  radius: '',
  latitude: '',
  longitude: '',
  status: false,
  points: '',
  refreshRate: '',
  account: '',
}

const Create = ({ admin }) => {
  const [error, setError] = useState(null)
  const createGeofence = useMutation(mutations.geofences.CREATE)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)

    const variables = {
      ...createGeofenceSchema.cast(values),
      status: values.status ? 'ACTIVE' : 'INACTIVE',
    }

    try {
      await createGeofence({ variables })
      navigate(routes.GEOFENCES_ROOT)
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  return (
    <Section
      heading="Create a New Zone"
      text="Give points to your loyal customers."
      bigHeader
    >
      <Card>
        <Form
          initialValues={initialValues}
          validationSchema={createGeofenceSchema}
          onSubmit={onSubmit}
          submitLabel="Create Zone"
          error={error}
        >
          <FormSection>
            <TextInput name="name" placeholder="Enter a name to help you identify this zone (only you will see this)" label="Zone Name" />
            <Toggle name="status" label={['Activate right away', 'Inactive, I will activate later']} />
          </FormSection>

          <FormSection
            heading="Location"
            text="Place your zone where your customers are. Typically, that will
              be your primary place of business. A wider zone can entice people visiting
              neighboring businesses."
          >
            <TextInput name="radius" placeholder="5 (meters)" type="number" label="Radius in meters" />
            <InputGroup>
              <TextInput name="latitude" placeholder="26.209698" type="number" label="Latitude" />
              <TextInput name="longitude" placeholder="-98.233922" type="number" label="Longitude" />
            </InputGroup>
          </FormSection>

          <FormSection
            heading="Points"
            text="Points are awarded to your customers whenever they cross into your zone. To
              keep customers from being overly awarded you must specify a refresh rate in
              minutes."
          >
            <InputGroup>
              <TextInput name="points" placeholder="5 (on zone entry)" type="number" label="Points Awarded on Entry" />
              <TextInput name="refreshRate" placeholder="300 (minutes)" type="number" label="Refresh Rate" />
            </InputGroup>
          </FormSection>

          {admin && (
            <FormSection
              heading="Admin"
              text="By default the person creating a zone will be its owner, that's you! If creating for a client please specify here."
            >
              <AccountSelect name="account" placeholder="Select the owner of this zone" label="Zone owner" />
            </FormSection>
          )}
        </Form>
      </Card>
    </Section>
  )
}

export default Create
