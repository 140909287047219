import React from 'react'
import { ApolloProvider } from 'react-apollo-hooks'

import Theme from './Theme'
import GetRole from './GetRole'
import Public from './Public'
import EnvBanner from './EnvBanner'
import Loading from '../Loading'

import createApolloClient from '../../utilities/createApolloClient'
import useAuthState from '../../utilities/useAuthState'

const App = () => {
  const { user, loading } = useAuthState()

  if (loading) return <Loading full />

  return (
    <Theme>
      <>
        <EnvBanner />
        {user && user.uid
          ? (
            <ApolloProvider client={createApolloClient()}>
              <GetRole />
            </ApolloProvider>
          )
          : <Public />
        }
      </>
    </Theme>
  )
}

export default App
