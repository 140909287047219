// nested routes, no slash
const nested = {
  ACCOUNT: 'account',
  GEOFENCES: 'geofences',
  ACTIVITY: 'activity',
  SETTINGS: 'settings',
  REWARDS: 'rewards',
  USERS: 'users',
  NEW: 'new',
  ID: ':id',
}

// root routes, require slash
const roots = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_OUT: '/signout',
  PASSWORD_RESET: '/amnesia',
  ACCOUNT_ROOT: `/${nested.ACCOUNT}`,
  GEOFENCES_ROOT: `/${nested.GEOFENCES}`,
  REWARDS_ROOT: `/${nested.REWARDS}`,
  USERS_ROOT: `/${nested.USERS}`,
  GEOFENCES_NEW: `/${nested.GEOFENCES}/${nested.NEW}`,
  REWARDS_NEW: `/${nested.REWARDS}/${nested.NEW}`,
  USERS_NEW: `/${nested.USERS}/${nested.NEW}`,
  ACCOUNT_SETTINGS: `/${nested.ACCOUNT}/${nested.SETTINGS}`,
}

const routes = {
  ...roots,
  ...nested,
}

export default routes
