/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import { useField } from 'formik'
import { Flex, Text } from 'rebass'
import { css } from 'styled-components/macro'

const inputStyle = css`
  color: ${({ theme }) => theme.colors.text.normal};
  background-color: #eff2f7;
  border: 1px solid #eff2f7;
  font-weight: 300;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: .75rem 1rem;
  border-radius: 3px;

  &:focus {
    background-color: ${({ theme }) => theme.colors.header};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline: none;
  }

  ::placeholder {
    color: #AFB8C6; 
  }
`

const TextInput = ({ name, label, ...others }) => {
  const [field, meta] = useField(name)
  return (
    <Flex
      flexDirection="column"
      mb={3}
    >
      <label htmlFor={name}>
        <Text
          {...field}
          {...others}
          id={name}
          as="input"
          type="checkbox"
          name={name}
          fontSize={2}
          mb={1}
          css={inputStyle}
        />
        {label}
      </label>
      {meta.touch && meta.error && (
        <Text color="error">
          {meta.error}
        </Text>
      )}
    </Flex>
  )
}

export default TextInput
