import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import {
  TextInput,
  InputGroup,
  Toggle,
  FormSection,
  Form,
} from '../Form'
import Card from '../Card'
import Loading from '../Loading'
import NotFound from '../NotFound'

import { updateUserSchema } from '../../utilities/validations'
import queries from '../../queries'
import mutations from '../../mutations'

const Edit = ({ id }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { data, error: queryError, loading } = useQuery(queries.users.GENERAL, {
    variables: { id },
    suspend: false,
  })
  const updateUser = useMutation(mutations.users.UPDATE)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setSuccess(null)
    setError(null)

    const variables = {
      ...updateUserSchema.cast(values),
      id,
      roles: values.admin ? 'ADMIN' : 'CLIENT',
    }

    try {
      await updateUser({ variables })
      setSuccess('User was successfully updated')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  if (queryError && queryError.message) return <Text color="error">{queryError.message}</Text>
  if (!loading && !data.user) return <NotFound noMargin />

  return (
    <Card>
      {loading
        ? <Loading />
        : (
          <Form
            initialValues={{
              email: get(data, 'user.email', ''),
              firstName: get(data, 'user.firstName', ''),
              lastName: get(data, 'user.lastName', ''),
              admin: get(data, 'user.roles', []).includes('ADMIN'),
            }}
            validationSchema={updateUserSchema}
            onSubmit={onSubmit}
            submitLabel="Update User"
            error={error}
            success={success}
          >
            <FormSection>
              <TextInput name="email" placeholder="Enter an email address" label="Email Address" />
              <Toggle name="admin" label="Admin" />
            </FormSection>

            <FormSection heading="General Information">
              <InputGroup>
                <TextInput name="firstName" placeholder="Enter a first name" label="First Name" />
                <TextInput name="lastName" placeholder="Enter a last name" label="Last Name" />
              </InputGroup>
            </FormSection>
          </Form>
        )
      }
    </Card>
  )
}

export default Edit
